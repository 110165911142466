// JsFromRoutes CacheKey 7474c5e42e254fa8d9461636019d5e12
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/sign_in'),
  create: definePathHelper('post', '/users/sign_in'),
  destroy: definePathHelper('delete', '/users/sign_out'),
  handleOtpStep: definePathHelper('post', '/users/auth/handle_otp_step'),
  handleSplashscreenStep: definePathHelper('post', '/users/auth/handle_splashscreen_step'),
}
